export default function Head({
  title,
  description,
  canonicalUrl,
}: {
  title: string;
  description: string;
  canonicalUrl: string;
}) {
  return (
    <>
      <title>{title || "HAMS FLY"}</title>
      <meta
        name="description"
        content={description || "The signature of excellence."}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />

      <link rel="canonical" href={canonicalUrl} />
    </>
  );
}
