import Image from "next/image";
import usBangla from "../../assets/flightsHome/usBangla.png";
import bimanbd from "../../assets/flightsHome/biman-bangla.png";
import emirates from "../../assets/flightsHome/emirates.png";
import qatarAirways from "../../assets/flightsHome/qatar-airways.png";
import singaporeAir from "../../assets/flightsHome/singapore-airlines.png";

const Partners = () => {
  return (
    <div data-aos="fade-up" className="hidden lg:block space-y-4 pb-6 mb-16">
      {/* <p className="lg:text-lg md:text-sm lg:block md:block hidden font-medium max-w-[500px] mx-auto">
        Discover with HAMS more than thousand of partners to bring you better
        travel deals.
      </p> */}
      <div className="flex flex-wrap items-center justify-center gap-16 w-[90%] mx-auto md:w-full">
        <Image alt="us-bangla" src={usBangla} />
        <Image alt="us-bangla" src={emirates} />
        <Image alt="us-bangla" src={singaporeAir} />
        <Image alt="us-bangla" src={qatarAirways} />
        <Image alt="us-bangla" src={bimanbd} />
      </div>
    </div>
  );
};

export default Partners;
