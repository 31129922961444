"use client";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadUserFromLocalStorage } from "@/redux/authSlice";
import FlightsHome from "./pages/flights/page";
import SignIn from "@/app/pages/signIn/page";

export default function Home() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  // On component mount, check if the user is logged in
  useEffect(() => {
    dispatch(loadUserFromLocalStorage());
  }, [dispatch]);

  return <main>{isLoggedIn ? <FlightsHome /> : <SignIn />}</main>;
}
